import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _7f157000 = () => interopDefault(import('../client/pages/cart/index.vue' /* webpackChunkName: "" */))
const _5a1e2ce0 = () => interopDefault(import('../client/pages/products/no-minimum/_.vue' /* webpackChunkName: "" */))
const _d7790b38 = () => interopDefault(import('../client/pages/products/closeout/_.vue' /* webpackChunkName: "" */))
const _2aa155ba = () => interopDefault(import('../client/pages/account/index.vue' /* webpackChunkName: "pages/account/index" */))
const _70fd9700 = () => interopDefault(import('../client/pages/cartitemsadded/index.vue' /* webpackChunkName: "pages/cartitemsadded/index" */))
const _770ab1aa = () => interopDefault(import('../client/pages/changepassword/index.vue' /* webpackChunkName: "pages/changepassword/index" */))
const _6d0aad1c = () => interopDefault(import('../client/pages/customer-artwork-proof/index.vue' /* webpackChunkName: "pages/customer-artwork-proof/index" */))
const _5245876b = () => interopDefault(import('../client/pages/designs/index.vue' /* webpackChunkName: "pages/designs/index" */))
const _0c5b6e3c = () => interopDefault(import('../client/pages/facets/index.vue' /* webpackChunkName: "pages/facets/index" */))
const _1782ee0d = () => interopDefault(import('../client/pages/favorites/index.vue' /* webpackChunkName: "pages/favorites/index" */))
const _bed0c044 = () => interopDefault(import('../client/pages/forgotpassword/index.vue' /* webpackChunkName: "pages/forgotpassword/index" */))
const _04744739 = () => interopDefault(import('../client/pages/health.vue' /* webpackChunkName: "pages/health" */))
const _0b26867e = () => interopDefault(import('../client/pages/not-found/index.vue' /* webpackChunkName: "pages/not-found/index" */))
const _7e0a2e3a = () => interopDefault(import('../client/pages/proof/index.vue' /* webpackChunkName: "pages/proof/index" */))
const _7125568c = () => interopDefault(import('../client/pages/proofitemsadded/index.vue' /* webpackChunkName: "pages/proofitemsadded/index" */))
const _58b4041c = () => interopDefault(import('../client/pages/quoteitemsadded/index.vue' /* webpackChunkName: "pages/quoteitemsadded/index" */))
const _fa8cc940 = () => interopDefault(import('../client/pages/resetpassword/index.vue' /* webpackChunkName: "pages/resetpassword/index" */))
const _12b1c96c = () => interopDefault(import('../client/pages/sampleitemsadded/index.vue' /* webpackChunkName: "pages/sampleitemsadded/index" */))
const _06d0667c = () => interopDefault(import('../client/pages/signin/index.vue' /* webpackChunkName: "pages/signin/index" */))
const _04656267 = () => interopDefault(import('../client/pages/signout/index.vue' /* webpackChunkName: "pages/signout/index" */))
const _3159bfdd = () => interopDefault(import('../client/pages/suppliers/index.vue' /* webpackChunkName: "pages/suppliers/index" */))
const _5ab2335d = () => interopDefault(import('../client/pages/testy/index.vue' /* webpackChunkName: "pages/testy/index" */))
const _27bc8bcb = () => interopDefault(import('../client/pages/version.vue' /* webpackChunkName: "pages/version" */))
const _612b9262 = () => interopDefault(import('../client/pages/version-app/index.vue' /* webpackChunkName: "pages/version-app/index" */))
const _849b08fc = () => interopDefault(import('../client/pages/cat/GeneralProductView.vue' /* webpackChunkName: "pages/cat/GeneralProductView" */))
const _5d49e7f8 = () => interopDefault(import('../client/pages/orders/products/index.vue' /* webpackChunkName: "pages/orders/products/index" */))
const _fdb80fe8 = () => interopDefault(import('../client/pages/products/utils.js' /* webpackChunkName: "pages/products/utils" */))
const _42593337 = () => interopDefault(import('../client/pages/products/search/mixin.js' /* webpackChunkName: "pages/products/search/mixin" */))
const _310dde0e = () => interopDefault(import('../client/pages/products/search/search.js' /* webpackChunkName: "pages/products/search/search" */))
const _514efd87 = () => interopDefault(import('../client/pages/sso/facebook/delete.vue' /* webpackChunkName: "pages/sso/facebook/delete" */))
const _6ba8bfe6 = () => interopDefault(import('../client/pages/sso/punch-out/_source.vue' /* webpackChunkName: "pages/sso/punch-out/_source" */))
const _13b6a1d5 = () => interopDefault(import('../client/pages/review/order/_orderId/product/_productId.vue' /* webpackChunkName: "pages/review/order/_orderId/product/_productId" */))
const _05fbedb6 = () => interopDefault(import('../client/pages/products/search/_.vue' /* webpackChunkName: "pages/products/search/_" */))
const _66fabf96 = () => interopDefault(import('../client/pages/blog/cat/_.vue' /* webpackChunkName: "pages/blog/cat/_" */))
const _8bacefe8 = () => interopDefault(import('../client/pages/approval/_orderId/index.vue' /* webpackChunkName: "pages/approval/_orderId/index" */))
const _8c1a1482 = () => interopDefault(import('../client/pages/artproof/_productSlug/index.vue' /* webpackChunkName: "pages/artproof/_productSlug/index" */))
const _0111fe44 = () => interopDefault(import('../client/pages/authors/_authorName/index.vue' /* webpackChunkName: "pages/authors/_authorName/index" */))
const _3924d370 = () => interopDefault(import('../client/pages/components/_componentId/index.vue' /* webpackChunkName: "pages/components/_componentId/index" */))
const _e7ae2992 = () => interopDefault(import('../client/pages/configureproduct/_productSlug/index.vue' /* webpackChunkName: "pages/configureproduct/_productSlug/index" */))
const _53604bf2 = () => interopDefault(import('../client/pages/customer-artwork-proof/_id/index.vue' /* webpackChunkName: "pages/customer-artwork-proof/_id/index" */))
const _5ebc2455 = () => interopDefault(import('../client/pages/designer/_productSlug/index.vue' /* webpackChunkName: "pages/designer/_productSlug/index" */))
const _56910666 = () => interopDefault(import('../client/pages/designs/_slug/index.vue' /* webpackChunkName: "pages/designs/_slug/index" */))
const _7618cc77 = () => interopDefault(import('../client/pages/facets/_slug/index.vue' /* webpackChunkName: "pages/facets/_slug/index" */))
const _4476639f = () => interopDefault(import('../client/pages/largequote/_productSlug/index.vue' /* webpackChunkName: "pages/largequote/_productSlug/index" */))
const _84eb8ea0 = () => interopDefault(import('../client/pages/orders/_id/index.vue' /* webpackChunkName: "pages/orders/_id/index" */))
const _42fc5a3c = () => interopDefault(import('../client/pages/payments/_orderId/index.vue' /* webpackChunkName: "pages/payments/_orderId/index" */))
const _ecbd5756 = () => interopDefault(import('../client/pages/presentation/_orderId/index.vue' /* webpackChunkName: "pages/presentation/_orderId/index" */))
const _7e8f988c = () => interopDefault(import('../client/pages/presentations/_id/index.vue' /* webpackChunkName: "pages/presentations/_id/index" */))
const _3177fdcf = () => interopDefault(import('../client/pages/printinvoice/_orderId/index.vue' /* webpackChunkName: "pages/printinvoice/_orderId/index" */))
const _9719a202 = () => interopDefault(import('../client/pages/products/_slug/index.vue' /* webpackChunkName: "pages/products/_slug/index" */))
const _6b4d9c25 = () => interopDefault(import('../client/pages/proof/_id/index.vue' /* webpackChunkName: "pages/proof/_id/index" */))
const _d320a15e = () => interopDefault(import('../client/pages/quickquote/_productSlug/index.vue' /* webpackChunkName: "pages/quickquote/_productSlug/index" */))
const _887c0648 = () => interopDefault(import('../client/pages/reorder/_id/index.vue' /* webpackChunkName: "pages/reorder/_id/index" */))
const _27c85976 = () => interopDefault(import('../client/pages/sample/_productSlug/index.vue' /* webpackChunkName: "pages/sample/_productSlug/index" */))
const _78599193 = () => interopDefault(import('../client/pages/sso/_service.vue' /* webpackChunkName: "pages/sso/_service" */))
const _44cfd908 = () => interopDefault(import('../client/pages/cat/_slug/brand.vue' /* webpackChunkName: "pages/cat/_slug/brand" */))
const _1d88e0f1 = () => interopDefault(import('../client/pages/orders/_id/track-shipment/index.vue' /* webpackChunkName: "pages/orders/_id/track-shipment/index" */))
const _2e8f170e = () => interopDefault(import('../client/pages/printinvoice/_orderId/short.vue' /* webpackChunkName: "pages/printinvoice/_orderId/short" */))
const _b54346f0 = () => interopDefault(import('../client/pages/products/_slug/reviews.vue' /* webpackChunkName: "pages/products/_slug/reviews" */))
const _0bd31726 = () => interopDefault(import('../client/pages/facets/_slug/_facet/index.vue' /* webpackChunkName: "pages/facets/_slug/_facet/index" */))
const _3450a24e = () => interopDefault(import('../client/pages/supplier/_psCode/_.vue' /* webpackChunkName: "pages/supplier/_psCode/_" */))
const _50b492d4 = () => interopDefault(import('../client/pages/cat/_slug/_.vue' /* webpackChunkName: "pages/cat/_slug/_" */))
const _430c7412 = () => interopDefault(import('../client/pages/_.vue' /* webpackChunkName: "pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/box/cart",
    component: _7f157000,
    name: "box/cart"
  }, {
    path: "/quote/cart",
    component: _7f157000,
    name: "quote/cart"
  }, {
    path: "/sample/cart",
    component: _7f157000,
    name: "sample/cart"
  }, {
    path: "/proof/cart",
    component: _7f157000,
    name: "proof/cart"
  }, {
    path: "/boxcart",
    component: _7f157000,
    name: "boxcart"
  }, {
    path: "/quotecart",
    component: _7f157000,
    name: "quotecart"
  }, {
    path: "/samplecart",
    component: _7f157000,
    name: "samplecart"
  }, {
    path: "/proofcart",
    component: _7f157000,
    name: "proofcart"
  }, {
    path: "/products/no-minimum",
    component: _5a1e2ce0,
    name: "products-no-minimum"
  }, {
    path: "/products/closeout",
    component: _d7790b38,
    name: "products-closeout"
  }, {
    path: "/account",
    component: _2aa155ba,
    name: "account"
  }, {
    path: "/cart",
    component: _7f157000,
    name: "cart"
  }, {
    path: "/cartitemsadded",
    component: _70fd9700,
    name: "cartitemsadded"
  }, {
    path: "/changepassword",
    component: _770ab1aa,
    name: "changepassword"
  }, {
    path: "/customer-artwork-proof",
    component: _6d0aad1c,
    name: "customer-artwork-proof"
  }, {
    path: "/designs",
    component: _5245876b,
    name: "designs"
  }, {
    path: "/facets",
    component: _0c5b6e3c,
    name: "facets"
  }, {
    path: "/favorites",
    component: _1782ee0d,
    name: "favorites"
  }, {
    path: "/forgotpassword",
    component: _bed0c044,
    name: "forgotpassword"
  }, {
    path: "/health",
    component: _04744739,
    name: "health"
  }, {
    path: "/not-found",
    component: _0b26867e,
    name: "not-found"
  }, {
    path: "/proof",
    component: _7e0a2e3a,
    name: "proof"
  }, {
    path: "/proofitemsadded",
    component: _7125568c,
    name: "proofitemsadded"
  }, {
    path: "/quoteitemsadded",
    component: _58b4041c,
    name: "quoteitemsadded"
  }, {
    path: "/resetpassword",
    component: _fa8cc940,
    name: "resetpassword"
  }, {
    path: "/sampleitemsadded",
    component: _12b1c96c,
    name: "sampleitemsadded"
  }, {
    path: "/signin",
    component: _06d0667c,
    name: "signin"
  }, {
    path: "/signout",
    component: _04656267,
    name: "signout"
  }, {
    path: "/suppliers",
    component: _3159bfdd,
    name: "suppliers"
  }, {
    path: "/testy",
    component: _5ab2335d,
    name: "testy"
  }, {
    path: "/version",
    component: _27bc8bcb,
    name: "version"
  }, {
    path: "/version-app",
    component: _612b9262,
    name: "version-app"
  }, {
    path: "/cat/GeneralProductView",
    component: _849b08fc,
    name: "cat-GeneralProductView"
  }, {
    path: "/orders/products",
    component: _5d49e7f8,
    name: "orders-products"
  }, {
    path: "/products/utils",
    component: _fdb80fe8,
    name: "products-utils"
  }, {
    path: "/products/search/mixin",
    component: _42593337,
    name: "products-search-mixin"
  }, {
    path: "/products/search/search",
    component: _310dde0e,
    name: "products-search-search"
  }, {
    path: "/sso/facebook/delete",
    component: _514efd87,
    name: "sso-facebook-delete"
  }, {
    path: "/sso/punch-out/:source?",
    component: _6ba8bfe6,
    name: "sso-punch-out-source"
  }, {
    path: "/review/order/:orderId?/product/:productId?",
    component: _13b6a1d5,
    name: "review-order-orderId-product-productId"
  }, {
    path: "/products/search/*",
    component: _05fbedb6,
    name: "products-search-all"
  }, {
    path: "/products/no-minimum/*",
    component: _5a1e2ce0,
    name: "products-no-minimum-all"
  }, {
    path: "/products/closeout/*",
    component: _d7790b38,
    name: "products-closeout-all"
  }, {
    path: "/blog/cat/*",
    component: _66fabf96,
    name: "blog-cat-all"
  }, {
    path: "/approval/:orderId",
    component: _8bacefe8,
    name: "approval-orderId"
  }, {
    path: "/artproof/:productSlug",
    component: _8c1a1482,
    name: "artproof-productSlug"
  }, {
    path: "/authors/:authorName",
    component: _0111fe44,
    name: "authors-authorName"
  }, {
    path: "/components/:componentId",
    component: _3924d370,
    name: "components-componentId"
  }, {
    path: "/configureproduct/:productSlug",
    component: _e7ae2992,
    name: "configureproduct-productSlug"
  }, {
    path: "/customer-artwork-proof/:id",
    component: _53604bf2,
    name: "customer-artwork-proof-id"
  }, {
    path: "/designer/:productSlug",
    component: _5ebc2455,
    name: "designer-productSlug"
  }, {
    path: "/designs/:slug",
    component: _56910666,
    name: "designs-slug"
  }, {
    path: "/facets/:slug",
    component: _7618cc77,
    name: "facets-slug"
  }, {
    path: "/largequote/:productSlug",
    component: _4476639f,
    name: "largequote-productSlug"
  }, {
    path: "/orders/:id",
    component: _84eb8ea0,
    name: "orders-id"
  }, {
    path: "/payments/:orderId",
    component: _42fc5a3c,
    name: "payments-orderId"
  }, {
    path: "/presentation/:orderId",
    component: _ecbd5756,
    name: "presentation-orderId"
  }, {
    path: "/presentations/:id",
    component: _7e8f988c,
    name: "presentations-id"
  }, {
    path: "/printinvoice/:orderId",
    component: _3177fdcf,
    name: "printinvoice-orderId"
  }, {
    path: "/products/:slug",
    component: _9719a202,
    name: "products-slug"
  }, {
    path: "/proof/:id",
    component: _6b4d9c25,
    name: "proof-id"
  }, {
    path: "/quickquote/:productSlug",
    component: _d320a15e,
    name: "quickquote-productSlug"
  }, {
    path: "/reorder/:id",
    component: _887c0648,
    name: "reorder-id"
  }, {
    path: "/sample/:productSlug",
    component: _27c85976,
    name: "sample-productSlug"
  }, {
    path: "/sso/:service?",
    component: _78599193,
    name: "sso-service"
  }, {
    path: "/cat/:slug?/brand",
    component: _44cfd908,
    name: "cat-slug-brand"
  }, {
    path: "/orders/:id?/track-shipment",
    component: _1d88e0f1,
    name: "orders-id-track-shipment"
  }, {
    path: "/printinvoice/:orderId?/short",
    component: _2e8f170e,
    name: "printinvoice-orderId-short"
  }, {
    path: "/products/:slug?/reviews",
    component: _b54346f0,
    name: "products-slug-reviews"
  }, {
    path: "/facets/:slug/:facet",
    component: _0bd31726,
    name: "facets-slug-facet"
  }, {
    path: "/supplier/:psCode?/*",
    component: _3450a24e,
    name: "supplier-psCode-all"
  }, {
    path: "/cat/:slug?/*",
    component: _50b492d4,
    name: "cat-slug-all"
  }, {
    path: "/*",
    component: _430c7412,
    name: "all"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
